
<template>
  <div class="row justify-content-center">
    <b-card
      class="col-12"
      :title="dataMateri.judul"
      :sub-title="dataMateri.deskripsi"
    >
    </b-card>

    <div
      class="card custom-card gutter-b mt-5 p-5 col-12"
      v-for="(data, i) in dataSoal"
      :key="i"
    >
      <h3>Pertanyaan {{ i + 1 }}</h3>
      <template v-if="data.tipe == 'Pilihan Ganda'">
        <div class="form-group my-5">
          <!-- <label>Tulis Soal</label> -->
          <b-form-textarea
            id="textarea-default"
            placeholder=""
            v-model="data.soal"
          ></b-form-textarea>
        </div>
        <div class="form-group my-5">
          <label>Tulis Jawaban</label>

          <div class="d-flex mb-2">
            <div
              class="flex-1 form-check form-check-custom form-check-solid my-auto"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="data.jawaban_benar"
                checked
                value="A"
              />
              <div class="form-check-label">A</div>
            </div>
            <div class="flex-1 ml-2" style="width: 100%">
              <input
                type="text"
                v-model="data.opsi_a"
                class="form-control"
                id="soal"
              />
            </div>
          </div>
          <div class="d-flex mb-2">
            <div
              class="flex-1 form-check form-check-custom form-check-solid my-auto"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="data.jawaban_benar"
                checked
                value="B"
              />
              <div class="form-check-label">B</div>
            </div>
            <div class="flex-1 ml-2" style="width: 100%">
              <input
                type="text"
                v-model="data.opsi_b"
                class="form-control"
                id="soal"
              />
            </div>
          </div>

          <div class="d-flex mb-2">
            <div
              class="flex-1 form-check form-check-custom form-check-solid my-auto"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="data.jawaban_benar"
                checked
                value="C"
              />
              <div class="form-check-label">C</div>
            </div>
            <div class="flex-1 ml-2" style="width: 100%">
              <input
                type="text"
                v-model="data.opsi_c"
                class="form-control"
                id="soal"
              />
            </div>
          </div>

          <div class="d-flex mb-2">
            <div
              class="flex-1 form-check form-check-custom form-check-solid my-auto"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="data.jawaban_benar"
                checked
                value="D"
              />
              <div class="form-check-label">D</div>
            </div>
            <div class="flex-1 ml-2" style="width: 100%">
              <input
                type="text"
                v-model="data.opsi_d"
                class="form-control"
                id="soal"
              />
            </div>
          </div>

          <div class="d-flex mb-2">
            <div
              class="flex-1 form-check form-check-custom form-check-solid my-auto"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="data.jawaban_benar"
                checked
                value="E"
              />
              <div class="form-check-label">E</div>
            </div>
            <div class="flex-1 ml-2" style="width: 100%">
              <input
                type="text"
                v-model="data.opsi_e"
                class="form-control"
                id="soal"
              />
            </div>
          </div>
        </div>

        <div style="width: 100%">
          <div class="float-right">
            <b-button
              type="submit"
              variant="danger"
              class="m-1"
              @click="deleteSoal(data.id)"
              >Hapus</b-button
            >
            <b-button type="submit" variant="primary" @click="simpanSoal(data)"
              >Simpan</b-button
            >
          </div>
        </div>
      </template>

      <template v-else-if="data.tipe == 'Benar-Salah'">
        <div class="form-group my-5">
          <!-- <label>Tulis Soal</label> -->
          <b-form-textarea
            id="textarea-default"
            placeholder=""
            v-model="data.soal"
          ></b-form-textarea>
        </div>
        <div class="form-group my-5">
          <label>Tulis Jawaban</label>

          <div class="d-flex mb-2">
            <div
              class="flex-1 form-check form-check-custom form-check-solid my-auto"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="data.jawaban_benar"
                checked
                value="A"
              />
              <div class="form-check-label">Benar</div>
            </div>
          </div>
          <div class="d-flex mb-2">
            <div
              class="flex-1 form-check form-check-custom form-check-solid my-auto"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="data.jawaban_benar"
                checked
                value="B"
              />
              <div class="form-check-label">Salah</div>
            </div>
          </div>
        </div>

        <div style="width: 100%">
          <div class="float-right">
            <b-button
              type="submit"
              variant="danger"
              class="m-1"
              @click="deleteSoal(data.id)"
              >Hapus</b-button
            >
            <b-button type="submit" variant="primary" @click="simpanSoal(data)"
              >Simpan</b-button
            >
          </div>
        </div>
      </template>
    </div>

    <div class="col-12">
      <b-button
        variant="outline-success"
        style="width: 100%"
        @click="tambahSoal"
      >
        <i class="fa fa-plus"></i> Tambah Pertanyaan
      </b-button>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FormGroup from "../../vue-bootstrap/FormGroup.vue";
import Swal from "sweetalert2";

export default {
  name: "soalBimtek",
  data() {
    FormGroup;
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      selected: "option1",
      dataMateri: {},
      dataSoal: [],
    };
  },
  methods: {
    loadMateriDetail() {
      this.axios
        .get(this.url + "/materi_pelatihan?id=" + this.$route.params.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.dataMateri = response.data.data;
        });
    },
    loadData() {
      this.dataSoal = [];
      this.axios
        .get(
          this.url + "/bank_soal?filter=id_materi,=," + this.$route.params.id,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataSoal = response.data.data;
        });
    },

    tambahSoal() {
      var jenis_soal = "";
      Swal.fire({
        showCloseButton: true,
        title: "Pilih Jenis Soal",
        denyButtonColor: "green",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Pilihan Ganda",
        denyButtonText: "Benar Salah",
        allowOutsideClick: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          jenis_soal = "Pilihan Ganda";
          const body = {
            id_materi: this.$route.params.id,
            tipe: jenis_soal,
          };
          this.axios
            .post(this.url + "/bank_soal", body, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              this.loadData();
            });
        } else if (result.isDenied) {
          jenis_soal = "Benar-Salah";
          const body = {
            id_materi: this.$route.params.id,
            tipe: jenis_soal,
          };
          this.axios
            .post(this.url + "/bank_soal", body, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              this.loadData();
            });
        }
      });
    },

    simpanSoal(data) {
      this.axios
        .post(this.url + "/bank_soal/" + data.id, data, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.loadData();
        });
    },

    deleteSoal(id) {
      Swal.fire({
        title: "Hapus Soal",
        text: "Apakah Anda yakin ingin menghapus?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(this.url + "/bank_soal/" + id, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              Swal.fire({
                title: "Terhapus!",
                text: "Soal berhasil di hapus.",
                icon: "success",
              });
              this.loadData();
            });
        }
      });
    },
  },
  mounted() {
    this.loadMateriDetail();
    this.loadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Soal Bimtek" }]);
  },
};
</script>
